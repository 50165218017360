.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* CUSTOM CSS */
.opt-box {
  align-items: center;
  border: 1px solid rgba(0,0,0,.24);
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 145px;
  justify-content: center;
  padding: 0 14px;
  text-align: center;
  transition: border-color .25s ease;
}

.opt-box__para {
  font-size: 14px;
  letter-spacing: .0045em;
  line-height: 1.1428571429;
}

.opt-box.active {
  border: 1px solid rgba(0,0,0,1);
}

.opt-box.active .opt-box__para {
  font-weight: bold;
}

/* New */
.SectionCoverPage__svgContainer__T3IUR {
  display: flex;
  justify-content: center;
  max-width: 100vw;
}

.SectionCoverPage__blurbLine__Gaj1S {
  align-items: center;
  display: flex;
  gap: 12px;
  justify-content: space-between;
}

.SectionCoverPage__numeral__MQsrj, .SectionCoverPage__numeralDuckegg__qMs8r {
  background-color: #dbede5;
  color: #054742;
  padding-top: 2px;
}

.SectionCoverPage__circle__jge7E {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 38px;
  justify-content: center;
  width: 38px;
}

.TypeBase-module__TitleSmall2__b6SEQ.TypeBase-module__Theinhardt__OQedf {
  font-size: 20px;
  letter-spacing: .001em;
  line-height: 1.3;
}



.SectionCoverPage__blurbCopy__mV2Tj {
  align-content: baseline;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
}

.SectionCoverPage__blurbs__ySHGf .SectionCoverPage__nonCaptionContainer__E6BaI {
  height: 36px;
  padding-left: 18px;
  padding-top: 4px;
}

.SectionCoverPage__blurbCopy__mV2Tj .SectionCoverPage__Body2__wbQa0.SectionCoverPage__rightText__A8nL4, .SectionCoverPage__blurbCopy__mV2Tj .SectionCoverPage__copy__AN17F, .SectionCoverPage__Body2__wbQa0.SectionCoverPage__Theinhardt__pSka_ {
  font-size: 17.5px;
  letter-spacing: 0;
  line-height: 1.3714285714;
}


.SectionCoverPage__blurbs__ySHGf .SectionCoverPage__line__LtTMe {
  background-color: #054742;
  height: 6px;
  width: 1px;
}

.SectionCoverPage__blurbs__ySHGf .SectionCoverPage__lineSpacer__EsNX7 {
  height: 6px;
}

.SectionCoverPage__blurbs__ySHGf .SectionCoverPage__line__LtTMe {
  background-color: #054742;
  height: 6px;
  width: 1px;
}

.SectionCoverPage__blurbs__ySHGf {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 32px;
  width: 500px;
}

.SectionCoverPage__flag__NXC5E {
  background-color: #054742;
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  padding: 8px 32px 6px;
}

.TypeBase-module__Book500__lGYLQ, .TypeBase-module__Medium500__CztgD {
  font-weight: 500;
}

.SectionCoverPage__greenBlob__kro6l {
  -webkit-animation: SectionCoverPage__pulse-green__X04C7 2s infinite;
  animation: SectionCoverPage__pulse-green__X04C7 2s infinite;
  background: #00cd46;
  border-radius: 50%;
  box-shadow: 0 0 0 0 #00cd46;
  height: 8px;
  margin: 3px 12px 3px 0;
  transform: scale(1);
  width: 8px;
}

@keyframes SectionCoverPage__pulse-green__X04C7 {
  0% {
    box-shadow: 0 0 0 0 rgba(0,205,70,.7);
    transform: scale(.95);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0,205,70,0);
    transform: scale(1);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0,205,70,0);
    transform: scale(.95);
  }
}

@media (min-width: 600px) {
  .SectionCoverPage__blurbs__ySHGf {
    border: 1px solid #054742;
    padding-left: 70px;
    padding-right: 70px;
  }

  .SectionCoverPage__flagContainer__HQZkA {
    margin: -16px 0px;
    max-width: 500px;
  }

  .SectionCoverPage__flag__NXC5E {
    transform: translate(-16px);
  }

}

.bg-green {
 cursor: pointer;
}

.bg-green.active {
  background-color: #054742;
  border-color: #054742;
  color: #fff;
}

.bg-blue {
  background-color: #deebff;
  /* display: flex; */
  /* grid-column: 1/span 10; */
  /* grid-row: 4/span 1; */
  margin-top: 32px;
  padding: 24px;
}
